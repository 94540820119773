function inSubscribeCarritoAbandonado() {
	if (indigitall && Notification.permission === "granted") {
		indigitall.topicsSubscribe(["carrito_abandonado_op2"]);
		var id_event_code = "carrito_abandonado_op2";
		console.log("Ingresa Retargeting y suscribe grupo Carrito abandonado", indigitall);
		indigitall.sendCustomEvent({
			eventType: id_event_code,
			customData: {},
			async: false
		});
	}
}

function inUnsubscribeCarritoAbandonado() {
	if (indigitall && Notification.permission == "granted") {
		console.log("Desuscribe de Topic No finaliza compra");
		indigitall.topicsUnsubscribe(["carrito_abandonado_op2"]);
	}
}

function sendToIndigitallExternalCode(externalCode) {
	if (!indigitall) return;

	indigitall.logIn(
		externalCode,
		(device) => {
			console.info("Success sending external code to Indigitall");
		},
		(error) => {
			console.error("Error sending external code to Indigitall");
		}
	);
}
